<template>
  <v-card class="pa-4" :class="darkmode || 'grey lighten-5'" flat>
    <div class="mb-5 font-weight-bold" style="cursor: pointer; width: 100px">
      <v-btn @click="$router.go(-1)" depressed>
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("app.back") }}
      </v-btn>
    </div>
    <HeaderInfo
      :info="dataTable"
      :textTotal="$t('e_test.total_students')"
      showTotal
      etest
      title
    />
    <div :class="darkmode || 'white'" class="pa-3">
      <v-row class="mb-5">
        <v-col cols="12" sm="6" md="6" lg="4" class="align-center d-flex">
          <v-text-field
            v-model="table.search"
            :label="$t('app.search')"
            :class="$vuetify.breakpoint.xsOnly && 'mt-2'"
            outlined
            dense
            hide-details
            clearable
            style="max-width: 200px"
            prepend-inner-icon="mdi-magnify"
            class="d-inline-block mr-2"
          />
          <div class="d-inline-block font-weight-bold">
            {{ dataTable.body.total }} {{ $t("app.student") }}
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="8" class="d-flex">
          <v-spacer />
          <div v-if="$vuetify.breakpoint.lgAndUp" class="d-flex flex-row">
            <!-- <download-excel
              :data="dataTable.body.data"
              :fields="fields"
              :name="`${$t('e_test.etest_result')}.xls`"
            >
              <v-btn
                v-show="!isMultipleSelect"
                class="gradient-primary caption mr-2"
                depressed
                dark
                dense
              >
                <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2">{{
                  $t("e_test.download_results")
                }}</span>
                <v-icon>mdi-cloud-download</v-icon></v-btn
              >
            </download-excel> -->
            <!-- :fetch="downloadExcel"
                :fields="fields"
                :header="['haha', 'hoho']"
                :name="excelFileName()" -->
            <v-btn
              v-if="dataTableTemp && dataTableTemp.length > 0"
              :loading="loadingDownloadExcel"
              class="gradient-primary caption mr-2"
              dark
              depressed
            >
              <download-excel
                :data="dataTableTemp"
                :fields="fields"
                :title="[
                  `${$t('app.school_year')}: ${
                    dataTableHeaderTemp.start_year
                  } - ${dataTableHeaderTemp.end_year}`,
                  `Semester: ${dataTableHeaderTemp.semester}`,
                  `${$t('app.class')}: ${dataTableHeaderTemp.class_name}`,
                  `${$t('app.subjects')}: ${dataTableHeaderTemp.lesson_name}`,
                  `${$t('e_test.table_header.detail_subjects.title')}: ${
                    dataTableHeaderTemp.title
                  }`,
                  ''
                ]"
                :name="
                  `Nilai ${dataTableHeaderTemp.lesson_name} Kelas ${dataTableHeaderTemp.class_name}`
                "
                :worksheet="dataTableHeaderTemp.title"
              >
                <span class="mr-2">
                  {{ $t("app.download") }} {{ $t("app.score") }}
                </span>
                <v-icon>mdi-cloud-download</v-icon>
              </download-excel>
            </v-btn>
            <v-btn
              class="gradient-primary-dark mr-2 caption"
              depressed
              dark
              dense
              @click="confirmModalEtest = true"
            >
              <span class="mr-2">
                {{ $t("e_test.publish_etest_result") }}
              </span>
              <v-icon>mdi-cloud-upload</v-icon>
            </v-btn>
            <v-btn
              v-if="!disablePost()"
              class="deep-purple darken-4 caption"
              depressed
              dark
              dense
              @click="confirmModal = true"
            >
              <span class="mr-2">
                {{ $t("e_test.value_posts") }}
              </span>
              <v-icon>mdi-upload</v-icon>
            </v-btn>
          </div>
          <div v-else class="ml-4 mt-2 align-center">
            <div class="d-flex align-center">
              <v-speed-dial
                v-model="menu"
                direction="bottom"
                style="z-index: 3"
                right
                transition="slide-y-reverse-transition"
              >
                <template v-slot:activator>
                  <v-btn v-model="menu" depressed color="primary" dark>
                    <v-icon v-if="menu">mdi-close</v-icon>
                    <v-icon v-else>mdi-menu-down-outline</v-icon>
                  </v-btn>
                </template>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      :disabled="dataTable.body.data.length ? false : true"
                      depressed
                      fab
                      dense
                      small
                    >
                      <download-excel
                        :data="dataTable.body.data"
                        :fields="fields"
                        :name="`${$t('e_test.etest_result')}.xls`"
                      >
                        <v-icon color="primary">mdi-cloud-download</v-icon>
                      </download-excel>
                    </v-btn>
                  </template>
                  <span>{{ $t("app.download") }}</span>
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-btn
                        depressed
                        fab
                        small
                        dense
                        @click="confirmModalEtest = true"
                      >
                        <v-icon color="primary">mdi-cloud-upload</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>
                    {{
                      dataTable.header.publish_student === 1
                        ? $t("e_test.e_test_results_published")
                        : $t("e_test.publish_etest_result")
                    }}
                  </span>
                </v-tooltip>
                <v-tooltip v-if="!disablePost()" left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      depressed
                      fab
                      dense
                      small
                      @click="confirmModal = true"
                    >
                      <v-icon color="primary">mdi-upload</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("e_test.value_posts") }}</span>
                </v-tooltip>
              </v-speed-dial>
            </div>
          </div>
          <div>
            <!-- <v-btn
              v-show="isMultipleSelect"
              class="gradient-primary"
              depressed
              dark
              dense
              >{{ $t("e_test.value_used") }}
              <v-icon class="ml-2">mdi-check-circle-outline</v-icon>
            </v-btn> -->

            <!-- <v-btn
              v-show="!isMultipleSelect"
              class="deep-purple darken-4 mx-2"
              depressed
              dark
              dense
              @click="confirmModal = true"
            >
              <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2">
                {{ $t("e_test.value_posts") }}
              </span>
              <v-icon>mdi-upload</v-icon>
            </v-btn> -->
            <!-- <v-menu offset-y rounded="lg">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="!isMultipleSelect"
                  v-bind="attrs"
                  v-on="on"
                  :class="$vuetify.breakpoint.smAndUp || 'mt-2'"
                  class="gradient-primary"
                  depressed
                  dark
                  dense
                >
                  <v-icon>mdi-sort-variant</v-icon>
                  <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2 caption">
                    Filter
                  </span>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(filter, i) in filters"
                  :key="i"
                  link
                  @click="filterTable(filter.data)"
                >
                  <v-list-item-title
                    ><v-icon color="primary">{{ filter.icon }}</v-icon>
                    {{ filter.title }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu> -->
          </div>
        </v-col>
      </v-row>
      <v-data-table
        v-model="modelTable"
        :headers="tableHeaders"
        :items="dataTable.body.data"
        :page.sync="table.page"
        :loading="table.loading"
        disable-pagination
        item-key="student"
        hide-default-footer
        class="elevation-0"
      >
        <template #item.number="{item}">
          {{ dataTable.body.data.indexOf(item) + dataTable.body.from - 1 + 1 }}
        </template>
        <template #item.score_label="{item}">
          {{ g_language === "en" ? item.used_lable.eng : item.used_lable.ind }}
        </template>
        <template #item.action="{item}">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :disabled="isMultipleSelect || table.loading ? true : false"
                dark
                small
                class="primary mr-2"
                icon
                @click="toggleDialogResult(item)"
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("e_test.correction") }}</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :disabled="isMultipleSelect || table.loading ? true : false"
                dark
                small
                class="deep-purple darken-4 mr-2"
                icon
                @click="toggleDialogValueDetails(item)"
              >
                <v-icon small>mdi-clipboard-text</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("e_test.score") }}</span>
          </v-tooltip>

          <!-- WIP: Upload data per student -->
          <!-- <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :disabled="isMultipleSelect || table.loading ? true : false"
                dark
                small
                class="purple accent-2"
                icon
                @click="dialogPostEtest(item)"
              >
                <v-icon small>mdi-cloud-upload</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("e_test.publish_etest_result") }}</span>
          </v-tooltip> -->
        </template>
      </v-data-table>
      <Pagination
        :length="dataTable.body.last_page"
        :total="dataTable.body.total"
        :current-page="table.page"
        :limit="table.limit"
        :handler="paginateChange"
      />
    </div>
    <DialogResult
      :open="dialogResult"
      :dataRequest="dataStudent"
      @close="toggleDialogResult"
    />
    <ValueDetails
      :open="dialogValue"
      :data="itemValueStudent"
      @close="toggleDialogValueDetails"
    />
    <ModalConfirm
      :close="() => (this.confirmModal = false)"
      :loading="confirmLoading"
      :isOpen="confirmModal"
      :save="postsValue"
      :content="$t('e_test.msg_posts_value')"
    />
    <ModalConfirm
      :close="() => (this.confirmModalEtest = false)"
      :loading="confirmLoadingEtest"
      :isOpen="confirmModalEtest"
      :save="publishToStudent"
      :content="$t('e_test.msg_posts_etest')"
    />
  </v-card>
</template>

<script>
import i18n from "@/i18n";
import {
  listScore,
  postingValue,
  publish_to_student
} from "@/api/admin/academic/eTest";
import store from "@/store";
import { mapGetters } from "vuex";
import moment from "moment/moment";

let typingTimer;
const doneTypingInterval = 750;

export default {
  components: {
    Pagination: () => import("@/components/Pagination"),
    DialogResult: () => import("./DialogResult"),
    HeaderInfo: () => import("../../components/HeaderInfo"),
    ValueDetails: () => import("./ValueDetails"),
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  created() {
    moment.locale(this.g_language);
    this.getListScore();
  },
  computed: {
    isMultipleSelect() {
      return this.modelTable.length != 0;
    },
    ...mapGetters(["g_language"]),
    darkmode() {
      return store.getters.g_darkmode;
    }
  },
  watch: {
    "table.search"() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.getListScore();
      }, doneTypingInterval);
    }
  },
  data() {
    return {
      confirmLoadingEtest: false,
      confirmModalEtest: false,
      menu: false,
      confirmModal: false,
      confirmLoading: false,
      dialogValue: false,
      itemValueStudent: {},
      studentSelected: [],
      modelTable: [],
      sheet: false,
      dataStudent: {},
      dialogResult: false,
      filters: [
        {
          title: i18n.t("e_test.filter.the_highest_score"),
          icon: "mdi-sort-ascending",
          data: { order: "score", sort: "desc" }
        },
        {
          title: i18n.t("e_test.filter.lowest_value"),
          icon: "mdi-sort-descending",
          data: { order: "score", sort: "asc" }
        },
        {
          title: i18n.t("e_test.filter.by_name"),
          icon: "mdi-sort-alphabetical",
          data: { order: "name", sort: "asc" }
        }
      ],
      table: {
        limit: 10,
        page: 1,
        search: "",
        loading: false,
        order: "score",
        sort: "desc",
        etest_class: this.$route.params.etestClass
      },
      dataTable: {
        header: { end: 0 },
        body: {
          data: [],
          total: 0
        }
      },
      tableHeaders: [
        {
          text: "#",
          value: "number",
          sortable: false
        },
        {
          text: i18n.t("app.name"),
          value: "name",
          sortable: false
        },
        {
          text: i18n.t("e_test.used_score"),
          value: "score_label",
          sortable: false
        },
        {
          text: i18n.t("e_test.used_score"),
          value: "used_score",
          sortable: false
        },
        {
          text: i18n.t("e_test.not_checked_yet"),
          value: "not_corrected_yet",
          sortable: false
        },
        {
          text: i18n.t("app.action"),
          align: "center",
          sortable: false,
          value: "action",
          width: 150
        }
      ],
      loadingDownloadExcel: false,
      fields: {
        [i18n.t("app.name")]: "name",
        [i18n.t("master_data.table.nis")]: {
          field: "nis",
          callback: value => " " + value
        },
        [i18n.t("app.score")]: "used_score"
      },
      dataTableTemp: []
    };
  },
  methods: {
    disablePost() {
      const endDate = moment.unix(
        parseInt(this.dataTable.header.end.toString().substr(0, 10))
      );
      return endDate.isAfter(moment(new Date()));
    },
    async downloadExcel() {
      this.dataTableTemp = [];
      this.loadingDownloadExcel = true;
      const query = JSON.parse(JSON.stringify(this.table));
      query.limit = this.dataTable.body.total;
      query.page = 1;

      const res = await listScore(query);

      this.loadingDownloadExcel = false;

      if (res.data.code) {
        this.dataTableTemp = res.data.data.body.data;
        this.dataTableHeaderTemp = res.data.data.header;
      }
    },
    async publishToStudent() {
      this.confirmLoadingEtest = true;
      try {
        const response = await publish_to_student({
          etest_class: this.$route.params.etestClass
        });
        if (response.data.code) {
          this.snackBar(true, this.$i18n.t("e_test.msg_success_posts_etest"));
          this.getListScore();
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        console.error("publishToStudent()\n", error);
        this.confirmModalEtest = false;
      }
      this.studentSelected = [];
      this.confirmLoadingEtest = false;
      this.confirmModalEtest = false;
    },
    dialogPostEtest(item) {
      if (this.isMultipleSelect) {
        this.studentSelected = this.modelTable.map(item => item.etest_class);
      } else {
        this.studentSelected = [item.etest_class];
      }
      this.confirmModalEtest = true;
    },
    async postsValue() {
      this.confirmLoading = true;
      try {
        const response = await postingValue({
          etest_class: this.$route.params.etestClass
        });
        if (response.data.code) {
          this.snackBar(true, this.$i18n.t("e_test.msg.success_posting_value"));
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        console.error("postsValue()\n", error);
        this.confirmLoading = false;
        this.confirmModal = false;
      }
      this.confirmLoading = false;
      this.confirmModal = false;
      this.getListScore();
    },
    toggleDialogValueDetails(item) {
      if (this.dialogValue) this.getListScore();
      if (item) this.itemValueStudent = item;
      this.dialogValue = !this.dialogValue;
    },
    filterTable(data) {
      this.table.order = data.order;
      this.table.sort = data.sort;
      this.getListScore();
    },
    toggleDialogResult(item) {
      if (this.dialogResult) this.getListScore();
      if (item) this.dataStudent = item;
      this.dialogResult = !this.dialogResult;
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    async getListScore() {
      this.table.loading = true;
      try {
        const response = await listScore(this.table);
        if (response.data.code) {
          this.dataTable = response.data.data;
          this.downloadExcel();
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.table.loading = false;
    },
    paginateChange(page, limit) {
      this.table.limit = limit;
      this.table.page = page;
      this.getListScore();
    }
  }
};
</script>
